/*! Mobile Nav
---------------------------------------------- */
.navigation-mobile {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000000;
  transform: translateX(400px);
  transition: all 0.3s ease-in-out;
  background-color: $white;

  overflow-y: auto;

  @media all and (max-width: 1205px) {
    width: 400px;
    height: 100vh;
  }

  @media all and (max-width: 499px) {
    width: 85%;
  }

  &.is-active {
    @media all and (max-width: 1205px) {
      display: block;
      transform: translateX(0px);
    }
  }

  &.active {
    @media all and (max-width: 1205px) {
      width: 400px;
      display: flex;
    }

    @media all and (max-width: 499px) {
      width: 85%;
    }
  }

  .navigation-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 9px 20px;
    background: $white;

    .logo {
      height: auto;
      margin-right: auto;

      svg {
        display: block;
        width: auto;
        margin: 5px 0;
        height: 34px;
      }
    }

    .site-btn {
      padding: 8px 14px;
      font-size: 14px;
    }
  }

  .navigation-close {
    position: relative;
    top: 4px;
    text-align: right;
    font-size: 28px;
    color: $dark;
    margin-left: 10px;
  }

  .navigation-link {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-top: 1px solid rgba(#000, 0.075);

    a,
    .is-link {
      margin-bottom: 10px;
      color: $colorAccent1;
      font-size: 16px;
      line-height: 1;
      background-color: $light;
      padding: 15px;
      border-radius:3px;
      text-transform: uppercase;
      font-family: $fontTitle;

      &.with-subnav {
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
        }

        span {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        svg {
          width: 12px;
          path {
            fill: $colorAccent1;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.is-active {
        background: $colorAccent1;
        color: #fff;
      }
    }

    .link-subnav {
      text-align: left;
      padding-top: 15px;

      display: none;

      &.is-active {
        display: block;
      }

      a {
        display: inline-block;
        font-family: $fontText;
        background: none;
        margin: 0;
        padding: 0;
        text-transform: none;
        font-weight: 300;
        font-size: 12px;
        text-align: left;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }

  .header-license-img {
    display: block;
    width: 100%;
    max-width: 80px;
    margin: 25px auto 0 auto;
  }

  .navigation-sublink {
    display: flex;
    flex-direction: column;
    padding: 0 20px 15px;

    a {
      font-size: 14px;
      color: $dark;
      padding-top: 8px;
      padding-bottom: 8px;
      color: $colorAccent1;

      &.alternate {
        color: $colorAccent2;
      }

      &.site-btn {
        color: $white;
        margin-bottom: 10px;

        &:hover {
          color: $dark;
        }
      }
    }
  }

  .navigation-footer {
    display: flex;
    align-items: center;
    padding: 20px 0;

    a {
      font-size: 13px;
      padding-left: 20px;

      i {
        position: relative;
        top: 1px;
        font-size: 16px;
      }
    }

    form {
      width: 100%;
      padding: 0 20px;

      input {
        width: 100%;
        height: 35px;
        padding: 5px 10px;
        font-size: 14px;
        color: rgba($dark, 0.7523);
        border: 1px solid rgba($dark, 0.123);
      }
    }
  }

  .navigation-footer-social {
    flex-grow: 1;

    a {
      padding: 0;
    }

    svg {
      margin-right: 20px;
      height: 24px;
      transition: all .3s ease-in-out;
    }
  }

  .subnav-mobile {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-bottom: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;

    &.active {
      max-height: inherit;
      opacity: 1;
      padding-bottom: 20px;
    }

    a {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}

.mobile-menu-trigger {
  display: none;
  width: 30px;
  height: 30px;
  margin-left: 12px;
  cursor: pointer;

  fill: #fff;

  @media (max-width: 1205px) {
    display: block;
  }
}

.navigation-mobile-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  background: rgba($dark, 0.70);

  opacity: 0;

  &.is-active {
    opacity: 1;
    z-index: 15;
  }
}

.white-nav {
  .mobile-menu-trigger {
    fill: $colorAccent1;
  }
}
