/*! Plateforme
---------------------------------------------- */
.plateforme {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;

  padding-top: 6vw;
  padding-bottom: 6vw;

  @media (max-width: 890px) {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .plateforme-container .reading-text.intro-reading-text {
    padding: 0;
    padding-bottom: 75px;
    margin: 0;

    @media (max-width: 890px) {
      padding-bottom: 35px;
    }
  }

  ul,
  ul li,
  a {
    margin: 0;
    padding: 0;
    list-style-type: none;
    color: inherit;
  }

  h4 {
    color: $colorAccent2;
  }

  .site-btn {
    display: inline-flex;
    align-items: center;
    padding: 15px 35px;
    color: #fff;
    margin-top: 20px;

    &:hover {
      color: $colorAccent1;

      svg {
        fill: $colorAccent1;
      }
    }
  }

  .filtered-by {
    color: $colorAccent1;
    margin-bottom: 25px;
  }

  .plateforme-item {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    cursor: pointer;

    font-family: $fontText;
    padding: 25px 25px;
    padding-right: 75px;
    border-left: 8px solid $colorAccent1;
    border-radius: 4px;
    font-weight: normal;
    background: $light;
    color: #43544B;
    margin-bottom: 10px;

    transition: background-color $t2 $bounce 0s, border-color $t2 $bounce 0s, color $t2 $bounce 0s;

    @media (max-width: 767px) {
      min-height: 65px;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    @media (max-width: 375px) {
      font-size: 13px;
    }

    &.is-active {
      background-color: $colorAccent1;
      border-color: $colorAccent5;
      color: #fff;

      .open-close {
        transform: translate(0, -50%) rotate(90deg);
        fill: #fff;
      }
    }

    &[data-is-empty="1"] {
      display: none;
    }

    span {
      display: inline-block;
      margin-right: 25px;
      font-family: $fontTitle;
      font-size: 150%;
    }

    .open-close {
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translate(0, -50%) rotate(0deg);

      fill: $colorAccent1;

      transition: transform $t2 $bounce 0s;
    }
  }

  .sub-item-wrap {
    margin-left: 30px;

    @media (max-width: 375px) {
      margin-left: 10px;
    }

    &.no-margin {
      margin-left: 0;

      @media (max-width: 375px) {
        margin-left: 0;
      }
    }
  }

  .plateforme-item.main-item.is-active + .sub-item-content.item-content {
    height: auto;
    transform: none;
    opacity: 1;
  }

  .main-item-content,
  .sub-item-content {
    position: relative;
    overflow: hidden;
    height: 0;
    opacity: 0;
    transform: scale(1, 0);
    transform-origin: center top;

    transition: transform $t2 $bounce 0s, opacity $t2 $bounce 0s;

    &.is-active {
      height: auto;
      transform: none;
      opacity: 1;
    }
  }

  .reading-text {
    position: relative;
    z-index: 2;
    max-width: 800px;
    padding: 30px 20px;
    margin-bottom: 10px;

    .regular-title, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
      padding: 0;
      color: $colorAccent1;
      text-transform: uppercase;
      font-size: 30px;
      margin-top: 40px;

      @media (max-width: 1100px) {
        font-size: 24px;
      }

      @media (max-width: 890px) {
        font-size: 22px;
        margin-top: 20px;
      }

      @media (max-width: 375px) {
        font-size: 20px;
      }
    }

    h4 {
      color: $colorAccent2;
    }

    .regular-text, p, ul, ul li {
      color: #43544B;
      font-weight: 400;
      margin-bottom: 15px;
      line-height: 1.6;
      font-size: 18px;

      @media (max-width: 1100px) {
        font-size: 16px;
      }

      @media (max-width: 375px) {
        font-size: 14px;
      }

      a {
        color: $colorAccent2;
      }
    }

    ul li {
      margin: 0;
    }

    li {
      margin-bottom: 0;
    }

    figure {
      margin: 0;
      padding: 0;
    }

    img.full,
    figure img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 4px;
    }

    .iframe-fix {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 25px 0;

      iframe {
        position: relative;
        width: 100% !important;
        height: auto !important;
        min-height: 32vw !important;

        @media (max-width: 700px) {
          min-height: 50vw !important;
        }
      }
    }
  }
}

.copy-link {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  background: $alternateLight;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;

  margin-top: 2.5vw;

  width: auto;
  padding-right: 45px;

  @media (max-width: 767px) {
    background: transparent;
  }

  input {
    position: absolute;
    top: -5555px;
    left: -55555px;
  }

  button {
    font-family: $fontTitle;
    border: none;
    background: $colorAccent2;
    min-width: 160px;
    color: #fff;
    font-weight: normal;
    padding: 15px 25px;
    font-size: 14px;
    margin-right: 10px;
    cursor: pointer;

    @media (max-width: 767px) {
      border-radius: 4px;
    }
  }

  a, span {
    padding: 10px;
    font-size: 12px;
    color: $colorAccent2;
    min-width: 45px;
    cursor: pointer;

    @media (max-width: 767px) {
      display: none;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-15px, -50%);

    width: 25px;
    fill: $colorAccent2;
    margin-top: 3px;

    @media (max-width: 767px) {
      display: none;
    }
  }
}
