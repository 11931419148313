/**
 * components/global.css
 *
 * Project-wide styles
 *
 */

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background: #fff;

  &.no-scroll {
    overflow: hidden;
  }
}

a {
  transition: all 0.6s ease;

  &:hover {
    /* opacity: 0.75; */
  }
}

#page-container {

}

.space-up main {
  padding-top: 85px;
}

.fluid-container {
  padding: 1rem 5vw;

  @media (max-width: 1400px) {
    padding: 1rem 20px;
  }
}

.max-container {
  max-width: 1240px;
  margin: 0 auto;
}

.flex-it {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  @media (max-width: 890px) {
    flex-wrap: wrap;

    > div {
      width: 100%;
    }
  }
}

.flex-it-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.flex-2-cols {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: nowrap;

  > div {
    width: calc(100% / 2);
  }

  @media (max-width: 890px) {
    flex-wrap: wrap;

    > div {
      width: 100%;
    }
  }
}

.flex-3-cols {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: nowrap;

  > div {
    width: calc(92% / 3);
  }

  @media (max-width: 890px) {
    flex-wrap: wrap;

    > div {
      width: 100%;
    }
  }
}

::selection {
  background-color: $colorAccent5;
  color: #fff;
}

::-moz-selection {
  background-color: $colorAccent5;
  color: #fff;
}

.hide-on-desktop {
  display: none;
  @media (max-width: 890px) {
    display: block;
  }
}

.hide-on-mobile {
  display: block;
  @media (max-width: 890px) {
    display: none;
  }
}

.hide-on-desktop-inline {
  display: none;
  @media (max-width: 890px) {
    display: inline;
  }
}

.hide-on-mobile-inline {
  display: inline;
  @media (max-width: 890px) {
    display: none;
  }
}

.hide-on-small-device {
  @media (max-width: 599px) {
    display: none;
  }
}

.cta-container {
  text-align: center;
}

/*! Lazyload
---------------------------------------------- */
img {
  opacity: 0;
  transition: opacity 0.45s ease 0.45s, padding 0s linear 0s, height 0s linear 0s;

  height: 0;
  width: 100%;
  /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
  padding-bottom: 56.25%;

  &.lazyloaded,
  &.flickity-lazyloaded {
    opacity: 1;
    height: auto;
    padding-bottom: 0;
  }

  &.force-lazyload {
    opacity: 1;
  }
}

.main-carousel .flickity-slider {
  img {
    opacity: 1;
  }
}

#map {
  img {
    opacity: 1;
    transition: none;
  }
}

.ignore {
  position: fixed;
  top: -5555px;
  left: -5555px;
}

.new-window-notice-link {
  position: relative;

  &:hover {
    .new-window-notice {
      opacity: 1;
    }
  }
}

.new-window-notice {
  display: inline-flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -120%);
  min-width: 175px;
  font-size: 12px;
  background: #fff;
  margin: 0;
  padding: 2px 5px;
  border-radius: 45px;
  text-align: center;
  border: 1px solid $colorAccent1;
  opacity: 0;

  display: none;
}

.spacer {
  padding-bottom: 5vw;
}

.top-alert-message {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 14px 20px;
  background: $colorAccent4;
  text-align: left;
  z-index: 9999;
  border-left: 4px solid $colorAccent2;

  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 32px;
    min-width: 32px;
    margin-left: auto;
    cursor: pointer;

    @media (max-width: 767px) {
      width: 24px;
      min-width: 24px;
    }
  }

  a {
    font-weight: 600;
    text-decoration: underline;
  }

  .text, p {
    display: inline-block;
    margin: 0 auto;
    padding: 0;
    color: $colorAccent2;
    font-weight: 400;
    padding-right: 80px;
    line-height: 1.46;
    font-size: 14px;
    text-align: left;

    @media (max-width: 767px) {
      padding-right: 40px;
    }
  }
}